import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getCurrentTenantBookings,
  getTenantMembersWithPermissions,
} from '../../../../../../../../data/actions'
import { BookingListComponent, ServiceTabs } from '../component'

export { ServiceTabs } from '../component'

const BookingListContainer = ({
  currentBookings,
  fetchTenant,
  fetchTenantBookings,
  isEditable,
  serviceTabs,
}) => {
  useEffect(() => {
    fetchTenant()
    fetchTenantBookings(currentBookings?.[0]?.tenantAcronym)
  }, [])
  return (
    <BookingListComponent
      bookings={currentBookings}
      isEditable={isEditable}
      serviceTabs={serviceTabs}
    />
  )
}

BookingListContainer.propTypes = {
  currentBookings: PropTypes.array,
  fetchTenant: PropTypes.func,
  fetchTenantBookings: PropTypes.func,
  isEditable: PropTypes.bool,
  serviceTabs: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ServiceTabs))),
}

export const BookingList = connect(
  ({ tenantBookings: { currentBookings } }, ownProps) => ({
    currentBookings: ownProps.currentBookings || currentBookings,
  }),
  {
    fetchTenantBookings: getCurrentTenantBookings,
    fetchTenant: getTenantMembersWithPermissions,
  },
)(BookingListContainer)
