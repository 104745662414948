import React from 'react'
import PropTypes from 'prop-types'
import { ErrorControl } from '../ErrorControl'
import { Hint } from '../Hint'

export const Select = ({
  className,
  label,
  value,
  disabled = false,
  placeHolder = 'Choose one',
  hint,
  error,
  options,
  onChange,
}) => (
  <wb-select-control class={className}>
    <wb-select aria-labelledby={error}>
      <label>{label}</label>
      <select
        name="select"
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        {placeHolder ? <option value="">{placeHolder}</option> : null}
        {options.map(({ name, id }) => (
          <option key={id || name} value={id || name}>
            {name}
          </option>
        ))}
      </select>
    </wb-select>
    <div>
      <ErrorControl error={error} />
      <Hint text={hint} />
    </div>
  </wb-select-control>
)

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeHolder: PropTypes.string,
  hint: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onChange: PropTypes.func,
}
