import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getCurrentBookingServices } from '../../../../../../../../data/actions'
import { selectCurrentBookingServices } from '../../../../../../../../data/selectors/tenantBookings'
import { BookingItemComponent, ServiceTabs } from '../component'

export { ServiceTabs } from '../component'

const BookingItemContainer = ({
  booking,
  getBookingServices,
  isEditable,
  serviceTabs,
  packageServices,
}) => {
  useEffect(() => {
    getBookingServices(booking)
  }, [])
  return (
    <BookingItemComponent
      booking={booking}
      isEditable={isEditable}
      packageServices={packageServices}
      serviceTabs={serviceTabs}
    />
  )
}

BookingItemContainer.propTypes = {
  booking: PropTypes.shape({
    comment: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.number,
    packageName: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        year: PropTypes.string,
        monthlyPrice: PropTypes.number,
        platformServicePackageId: PropTypes.string,
      }),
    ),
    provider: PropTypes.string,
    slug: PropTypes.string,
    startDate: PropTypes.string,
    tenantAcronym: PropTypes.string,
    tenantName: PropTypes.string,
  }),
  getBookingServices: PropTypes.func,
  isEditable: PropTypes.bool,
  packageServices: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      provider: PropTypes.string,
      serviceKey: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  serviceTabs: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ServiceTabs))),
}

export const BookingItem = connect(
  ({ tenantBookings: { services } }, ownProps) => ({
    packageServices: selectCurrentBookingServices(
      ownProps.booking.packageSlug,
      services,
    ),
  }),
  {
    getBookingServices: getCurrentBookingServices,
  },
)(BookingItemContainer)
