import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getBookingServiceProperties,
  setModal,
  postNotification,
  getBookingServiceProperty,
} from '../../../../../../../../../../data/actions'
import { selectCurrentBookingServiceProperties } from '../../../../../../../../../../data/selectors/tenantBookings'
import { BookingServicePropertiesComponent } from '../component'

const BookingServicePropertiesContainer = ({
  service,
  booking,
  serviceProperties,
  isEditable,
  getProperties,
  getProperty,
  notify,
  dispatchModal,
}) => {
  useEffect(() => {
    getProperties(booking, service)
  }, [])
  const onClickCopy = (property) => {
    navigator.clipboard.writeText(
      `https://${process.env.TENANTS_API_HOST}/tdms/v1/platformServiceProperties/platformBooking/${booking.id}/${service.slug}/${property.slug}`,
    )
    notify({
      message: 'The link was copied successfully',
    })
  }
  const onClickEdit = (property) =>
    getProperty(booking.id, service.slug, property, () => {
      dispatchModal({
        variant: 'bookingServiceProperty',
        mode: 'edit',
        bookingId: booking.id,
        bookingSlug: booking.packageDisplayName,
        serviceSlug: service.slug,
        onCancel: () => dispatchModal({}),
      })
    })
  return (
    <BookingServicePropertiesComponent
      isEditable={isEditable}
      serviceProperties={serviceProperties}
      onClickCopy={onClickCopy}
      onClickEdit={onClickEdit}
    />
  )
}

BookingServicePropertiesContainer.propTypes = {
  service: PropTypes.object,
  booking: PropTypes.object,
  serviceProperties: PropTypes.array,
  isEditable: PropTypes.bool,
  notify: PropTypes.func,
  getProperty: PropTypes.func,
  getProperties: PropTypes.func,
  dispatchModal: PropTypes.func,
}

export const BookingServiceProperties = connect(
  (
    {
      tenantBookings: { properties },
      tenants: { isWhitelisted, isAlmighty },
      sso: { providerRoles },
    },
    ownProps,
  ) => ({
    serviceProperties: selectCurrentBookingServiceProperties(
      ownProps.booking.id,
      ownProps.service.slug,
      properties,
    ),
    isEditable:
      ownProps.isEditable &&
      (isWhitelisted ||
        isAlmighty ||
        Boolean(providerRoles[ownProps.service.providerSlug])),
  }),
  {
    getProperties: getBookingServiceProperties,
    getProperty: getBookingServiceProperty,
    dispatchModal: setModal,
    notify: postNotification,
  },
)(BookingServicePropertiesContainer)
