import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import { Datepicker } from '../../../../../../../common'

export const MemberUntilDateEditor = ({
  date,
  expired,
  editMode,
  onDateChange,
}) => (
  <div>
    {editMode ? (
      <Datepicker
        label="Choose Date"
        value={date}
        onChange={(e) => onDateChange(e.target.value)}
        min={moment().add(1, 'days').format('YYYY-MM-DD')}
        max={moment().add(90, 'days').format('YYYY-MM-DD')}
      />
    ) : (
      <div
        className={classNames({
          'tenant-members--user-expired': expired,
        })}
      >
        {date}
      </div>
    )}
  </div>
)
MemberUntilDateEditor.propTypes = {
  date: PropTypes.string,
  expired: PropTypes.bool,
  editMode: PropTypes.bool,
  onDateChange: PropTypes.func,
}
