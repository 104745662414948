import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table'
import { connect } from 'react-redux'
import {
  setModal,
  getWebHooks,
  changeWebHook,
  addNewWebHook,
  removeWebHook,
} from '../../../../../../../data/actions'
import { generateColumns, fuzzyFilter } from './tableUtils'
import { TableComponent } from '../components/TableComponent'

const WhTable = ({
  webHooks,
  dispatchModal,
  fetchWebHooks,
  deleteWebHook,
  updateWebHook,
  addWebHook,
}) => {
  const [globalFilter, setGlobalFilter] = useState('')

  useEffect(() => {
    fetchWebHooks()
  }, [])

  const onCancel = () => dispatchModal({})
  const onDeleteWebHook = (row) => deleteWebHook(row.original.id, onCancel())

  const generateActionItemFunctions = (row) => ({
    onClickEdit: () =>
      dispatchModal({
        fullScreen: true,
        variant: 'addWebHooks',
        fullWidth: true,
        onApprove: updateWebHook,
        withoutPadding: true,
        webHookId: row.original.id,
        onCancel,
      }),
    onClickRemove: () =>
      dispatchModal({
        variant: 'prompt',
        header: 'Are you sure you want to remove the web-hook?',
        okText: 'Remove',
        onApprove: () => onDeleteWebHook(row),
        withoutPadding: true,
        onCancel,
      }),
  })

  const columns = useMemo(
    () => generateColumns((row) => generateActionItemFunctions(row)),
    [],
  )

  const table = useReactTable({
    data: webHooks.map((row) => ({
      ...row,
      __tdmsfeClassNames: [`wh-table-${row.status}`],
    })),
    columns,
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
  })

  return (
    <TableComponent
      onAddNew={() =>
        dispatchModal({
          fullScreen: true,
          variant: 'addWebHooks',
          onApprove: addWebHook,
          fullWidth: true,
          withoutPadding: true,
          header: 'Add new webhook',
          onCancel: () => dispatchModal({}),
        })
      }
      table={table}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
    />
  )
}

WhTable.propTypes = {
  webHooks: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      enabled: PropTypes.bool,
      eventSlugs: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
      producerSlug: PropTypes.string,
      protocol: PropTypes.object,
      providerSlugs: PropTypes.arrayOf(PropTypes.string),
      scopes: PropTypes.arrayOf(PropTypes.string),
      systemWebhook: PropTypes.bool,
      updatedAt: PropTypes.string,
    }),
  ),
  dispatchModal: PropTypes.func,
  fetchWebHooks: PropTypes.func,
  deleteWebHook: PropTypes.func,
  updateWebHook: PropTypes.func,
  addWebHook: PropTypes.func,
}

export const WebhooksTable = connect(
  ({ hermes: { webHooks } }) => ({
    webHooks,
  }),
  {
    dispatchModal: setModal,
    fetchWebHooks: getWebHooks,
    deleteWebHook: removeWebHook,
    updateWebHook: changeWebHook,
    addWebHook: addNewWebHook,
  },
)(WhTable)
