import PropTypes from 'prop-types'
import React from 'react'
import { Accordion, WbAccordion } from '../../../../../../../common'
import { Tabs } from '../../Tabs'
import { BookingServiceDetails } from '../components/BookingServiceDetails'
import { BookingServicePermissions } from '../components/BookingServicePermissions'
import { BookingServiceProperties } from '../components/BookingServiceProperties'

export const ServiceTabs = {
  DETAILS: 'details',
  PERMISSIONS: 'permissions',
  PROPERTIES: 'properties',
}

export const BookingItemComponent = ({
  booking,
  isEditable,
  packageServices,
  serviceTabs = [
    ServiceTabs.DETAILS,
    ServiceTabs.PROPERTIES,
    ServiceTabs.PERMISSIONS,
  ],
}) => {
  const tabs = {}
  if (
    serviceTabs.includes(ServiceTabs.PERMISSIONS) &&
    booking.packageAllowBookingPermissions
  ) {
    tabs.Permissions = BookingServicePermissions
  }
  if (serviceTabs.includes(ServiceTabs.PROPERTIES)) {
    tabs.Properties = BookingServiceProperties
  }
  if (serviceTabs.includes(ServiceTabs.DETAILS)) {
    tabs.Details = BookingServiceDetails
  }

  const Component = Object.keys(tabs).length ? (
    tabs[Object.keys(tabs)[0]]
  ) : (
    <div className="wbx-notification wbx-notification--warning">
      <div className="wbx-notification__body">
        <wb-badge variant="warning"></wb-badge>
        <span className="wbx-notification__content">Services Unavailable</span>
      </div>
    </div>
  )

  const renderComponent = (service) =>
    Object.keys(tabs).length > 1 ? (
      <Tabs content={tabs} props={{ service, booking, isEditable }} />
    ) : (
      <>
        <hr className="wb-margin-bottom-xs" />
        <Component
          booking={booking}
          isEditable={isEditable}
          service={service}
        />
      </>
    )

  return (
    <Accordion>
      {packageServices.map((service) => (
        <WbAccordion title={service.name} key={service.id}>
          {renderComponent(service)}
        </WbAccordion>
      ))}
    </Accordion>
  )
}

BookingItemComponent.propTypes = {
  booking: PropTypes.shape({
    comment: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.number,
    packageAllowBookingPermissions: PropTypes.bool,
    packageName: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        monthlyPrice: PropTypes.number,
        platformServicePackageId: PropTypes.string,
        year: PropTypes.string,
      }),
    ),
    provider: PropTypes.string,
    slug: PropTypes.string,
    startDate: PropTypes.string,
    tenantAcronym: PropTypes.string,
    tenantName: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  packageServices: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      provider: PropTypes.string,
      serviceKey: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  serviceTabs: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ServiceTabs))),
}
