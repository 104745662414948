import React from 'react'
import PropTypes from 'prop-types'
import { BookingList, ServiceTabs } from './components/BookingList'
import {
  Modal,
  ModalFooter,
  ModalContent,
  ModalLevel,
  ModalHeader,
  Button,
} from '../../../../common'

export { ServiceTabs } from './components/BookingList'

export const TenantBookingsDialog = ({
  booking,
  isEditable = false,
  onClose,
  serviceTabs,
}) => (
  <Modal className="tenant-dialog" onCloseModal={onClose}>
    <ModalHeader />
    <ModalLevel>
      <ModalContent
        headline="Tenant Bookings"
        className="tenant-dialog__content"
      >
        <BookingList
          currentBookings={booking && [booking]}
          isEditable={isEditable}
          serviceTabs={serviceTabs}
        />
      </ModalContent>
      <ModalFooter class="tenant-dialog__footer">
        <div></div>
        <Button size="large" variant="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalLevel>
  </Modal>
)

TenantBookingsDialog.propTypes = {
  booking: PropTypes.shape({
    comment: PropTypes.string,
    endDate: PropTypes.string,
    id: PropTypes.number,
    packageAllowBookingPermissions: PropTypes.bool,
    packageDisplayName: PropTypes.string,
    packageName: PropTypes.string,
    packageSlug: PropTypes.string,
    provider: PropTypes.string,
    startDate: PropTypes.string,
    tenantAcronym: PropTypes.string,
    tenantName: PropTypes.string,
    variantName: PropTypes.string,
    variantSlug: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  onClose: PropTypes.func,
  serviceTabs: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ServiceTabs))),
}
