import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  addTenantBooking,
  getTenantBookings,
  removeTenantBooking,
  setBookings,
  setModal,
  updateTenantBooking,
} from '../../../../../data/actions'
import {
  TenantBookingsDialog,
  ServiceTabs,
} from '../../../../apps/tenant/components/tenantBookingsDialog'
import { BookingsComponent } from '../component'

const BookingsContainer = ({
  tenants,
  bookings,
  getBookings,
  addBooking,
  updateBooking,
  updateBookings,
  deleteBooking,
  dispatchModal,
}) => {
  const [selectedTenant, setSelectedTenant] = useState({ value: '', label: '' })
  const [selectedBooking, setSelectedBooking] = useState(null)
  useEffect(() => {
    if (selectedTenant) {
      getBookings(selectedTenant.value)
    }
    return () => updateBookings([])
  }, [selectedTenant])

  const onCancel = () => dispatchModal({})
  const onDeleteBooking = (booking) => {
    dispatchModal({
      variant: 'prompt',
      okText: 'Remove',
      onApprove: () => deleteBooking(selectedTenant.value, booking.id),
      header: 'Are you sure you want to delete selected Booking?',
      onCancel,
    })
  }

  const onEditBooking = (booking) =>
    dispatchModal({
      variant: 'booking',
      mode: 'edit',
      onApprove: (bookingData) =>
        updateBooking(selectedTenant.value, booking.id, bookingData),
      header: 'Edit booking',
      booking: booking,
      onCancel,
    })

  const onEditProperties = (booking) => {
    setSelectedBooking(booking)
  }

  const onAddBooking = () =>
    dispatchModal({
      variant: 'booking',
      mode: 'add',
      onApprove: (priceVariantId, bookingData) =>
        addBooking(selectedTenant.value, priceVariantId, bookingData),
      header: 'Add booking',
      onCancel,
    })

  const addBookingDisabled = !selectedTenant?.value

  return [
    selectedBooking ? (
      <TenantBookingsDialog
        key="editbooking"
        isEditable
        onClose={() => setSelectedBooking(null)}
        booking={selectedBooking}
        serviceTabs={[ServiceTabs.PROPERTIES]}
      />
    ) : null,
    <BookingsComponent
      key="bookingList"
      tenants={tenants}
      selectedTenant={selectedTenant}
      addBookingDisabled={addBookingDisabled}
      bookings={bookings}
      actions={{
        setSelectedTenant,
        onEditBooking,
        onDeleteBooking,
        onAddBooking,
        onEditProperties,
      }}
    />,
  ]
}

BookingsContainer.propTypes = {
  addBooking: PropTypes.func,
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      comment: PropTypes.string,
      tenantName: PropTypes.string,
      tenantAcronym: PropTypes.string,
      packageName: PropTypes.string,
      packageDisplayName: PropTypes.string,
      packageAllowBookingPermissions: PropTypes.bool,
      packageSlug: PropTypes.string,
      provider: PropTypes.string,
      variantName: PropTypes.string,
      variantSlug: PropTypes.string,
    }),
  ),
  deleteBooking: PropTypes.func,
  dispatchModal: PropTypes.func,
  getBookings: PropTypes.func,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  updateBooking: PropTypes.func,
  updateBookings: PropTypes.func,
}

export const Bookings = connect(
  ({ sso: { roles }, tenants: { allTenants }, backOffice: { bookings } }) => ({
    tenants: Object.values(allTenants)
      .filter(({ status }) => status !== 'archived')
      .map(({ acronym, name, providerSlug }) => ({
        value: acronym,
        label: `${name} (${acronym})`,
        providerSlug,
      })),
    bookings,
  }),
  {
    addBooking: addTenantBooking,
    deleteBooking: removeTenantBooking,
    dispatchModal: setModal,
    getBookings: getTenantBookings,
    updateBooking: updateTenantBooking,
    updateBookings: setBookings,
  },
)(BookingsContainer)
