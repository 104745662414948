import React from 'react'
import { flexRender } from '@tanstack/react-table'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const TanstackTableWrapper = ({ table }) => {
  return (
    <table
      className="wb-table wb-table--border-horizontal"
      style={{ width: '100%' }}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  )}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          let rowClassNames = row.original?.__tdmsfeClassNames || []
          rowClassNames = rowClassNames.reduce(
            (acc, cur) => ({ ...acc, [cur]: true }),
            {},
          )

          // This is a Tenant Member specific thing, the class should have been pre-processed
          // and set as a class to apply to the row **before** getting to a generic component!
          rowClassNames = {
            ...rowClassNames,
            'wb-table__flagged-deletion': row.original?.isSubjectToRemoval,
          }

          return (
            <tr key={row.id} className={classNames(rowClassNames)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

TanstackTableWrapper.propTypes = {
  table: PropTypes.shape({
    getHeaderGroups: PropTypes.func,
    getRowModel: PropTypes.func,
  }),
}
