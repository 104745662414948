import PropTypes from 'prop-types'
import React from 'react'
import { Card } from '../../../../../../../common'
import { BookingItem, ServiceTabs } from '../../BookingItem'

export { ServiceTabs } from '../../BookingItem'

export const BookingListComponent = ({ bookings, isEditable, serviceTabs }) => (
  <div className="wb-padding-s" style={{ background: '#ffffff' }}>
    {bookings.length ? (
      bookings.map((booking) => (
        <div key={booking.id} className="wb-margin-bottom-xs">
          <Card>
            <h2 className="wb-padding-vertical-xs wb-padding-start-xs">
              {booking.packageDisplayName}
            </h2>
            <BookingItem
              booking={booking}
              isEditable={isEditable}
              serviceTabs={serviceTabs}
            />
          </Card>
        </div>
      ))
    ) : (
      <div className="no_bookings_div">No Bookings found</div>
    )}
  </div>
)

BookingListComponent.propTypes = {
  bookings: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      endDate: PropTypes.string,
      id: PropTypes.number,
      packageDisplayName: PropTypes.string,
      packageName: PropTypes.string,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          monthlyPrice: PropTypes.number,
          platformServicePackageId: PropTypes.string,
          year: PropTypes.string,
        }),
      ),
      provider: PropTypes.string,
      slug: PropTypes.string,
      startDate: PropTypes.string,
      tenantAcronym: PropTypes.string,
      tenantName: PropTypes.string,
    }),
  ),
  isEditable: PropTypes.bool,
  serviceTabs: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ServiceTabs))),
}
