import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, TooltipHost } from '../../../../../../common'
import { BookingDetails } from './BookingDetails'

export const BookingItem = ({
  booking,
  onEditBooking,
  onEditProperties,
  onDeleteBooking,
}) => (
  <li
    className={classnames(
      'bookings-list__item wb-card wb-card--white wb-padding-xs',
      {
        'bookings-list__item--expired': booking.expired,
      },
    )}
  >
    <BookingDetails booking={booking} />
    <div className="bookings-list__item-actions">
      <TooltipHost message="Edit" position="bottom">
        <Button
          variant="primary"
          size="small"
          withIcon="edit"
          iconOnly
          disabled={!booking.isEditable}
          onClick={onEditBooking}
        >
          Edit
        </Button>{' '}
      </TooltipHost>
      <TooltipHost message="Properties" position="bottom">
        <Button
          variant="secondary"
          size="small"
          className="wb-margin-start-xxs"
          withIcon="bds/gear/24"
          iconOnly
          disabled={!booking.isEditable}
          onClick={onEditProperties}
        >
          Properties
        </Button>{' '}
      </TooltipHost>
      {booking.isRemovable && (
        <TooltipHost message="Delete" position="bottom">
          <Button
            variant="secondary"
            size="small"
            className="wb-margin-start-xxs"
            onClick={onDeleteBooking}
            withIcon="bds/trash/24"
            iconOnly
          >
            Delete
          </Button>
        </TooltipHost>
      )}
    </div>
  </li>
)

BookingItem.propTypes = {
  booking: PropTypes.shape({
    packageName: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    serviceName: PropTypes.string,
    variantName: PropTypes.string,
    comment: PropTypes.string,
    isEditable: PropTypes.bool,
    isRemovable: PropTypes.bool,
    expired: PropTypes.bool,
  }),
  onEditBooking: PropTypes.func,
  onEditProperties: PropTypes.func,
  onDeleteBooking: PropTypes.func,
}
