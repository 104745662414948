import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { bulkActions } from '../../utils'
import {
  Button,
  Select,
  ReactSelect,
  ReactSelectCreatable,
  SearchInput,
  Datepicker,
} from '../../../../../../../common'

export const TableHeader = ({
  rowSelection,
  data,
  selectedAction,
  extendFormData,
  globalFilter,
  tenantRoles,
  selectedRoles,
  selectedLabels,
  expirationFilter,
  addLabels,
  currentTenantMembers,
  actions: {
    deselectAllUsers,
    setSelectedAction,
    updateInputState,
    bulkApplyAction,
    setSelectedRoles,
    setSelectedLabels,
    setGlobalFilter,
    setExpirationFilter,
    setAddLabels,
  },
}) => {
  const labelsObject = [
    ...new Set(currentTenantMembers.map((user) => user.labels).flat()),
  ]
  const labels = labelsObject.map((label) => ({ label, value: label }))

  const selectedUsers = Object.keys(rowSelection).map((index) => data[index])

  const rolesObject = {}
  const roles = tenantRoles.map(({ name, description }) => {
    rolesObject[name] = description
    return {
      label: description,
      value: name,
    }
  })

  const checkApplyButtonDisabled = () => {
    if (!selectedUsers.length) {
      return true
    }
    if (selectedAction === 'extend') {
      return !extendFormData.tenantUserRoleExpiryDate
    }

    if (selectedAction === 'addLabel') {
      return !addLabels.length
    }
    return false
  }

  return (
    <div className="tenant-members__header">
      <div className="tenant-members__header-left">
        <div className="tenant-members__header-column">
          <div className="tenant-members__user-info">
            ({selectedUsers.length}) User selected
          </div>
          <div className="wb-margin-top-xs">
            {selectedUsers.length ? (
              <Button
                variant="tertiary"
                size="small"
                onClick={deselectAllUsers}
                withIcon="bds/close/24"
              >
                Deselect All
              </Button>
            ) : null}
          </div>
        </div>
        <div className="wb-margin-start-xs">
          <div>
            <div className="tenant-members__header__bulk-actions">
              <div>
                <Select
                  label="Bulk Actions"
                  value={selectedAction}
                  disabled={!selectedUsers.length}
                  onChange={(e) => setSelectedAction(e.target.value)}
                  options={bulkActions}
                  placeHolder="Please choose an Action"
                />
              </div>
              {selectedUsers.length && selectedAction === 'extend' ? (
                <div className="wb-margin-start-xs">
                  <Datepicker
                    label="Choose date"
                    value={extendFormData.tenantUserRoleExpiryDate}
                    onChange={(e) =>
                      updateInputState(
                        'tenantUserRoleExpiryDate',
                        e.target.value,
                      )
                    }
                    min={moment().add(1, 'days').format('YYYY-MM-DD')}
                    max={moment().add(90, 'days').format('YYYY-MM-DD')}
                  />
                </div>
              ) : null}

              {selectedUsers.length && selectedAction === 'addLabel' ? (
                <div className="wb-margin-start-xs">
                  <ReactSelectCreatable
                    options={labels}
                    editMode
                    label="Add Labels"
                    disabled={!selectedUsers.length}
                    onLabelInputChange={(newLabels) => {
                      setAddLabels(newLabels)
                    }}
                    value={addLabels}
                  />
                </div>
              ) : null}
              {selectedUsers.length && selectedAction ? (
                <div className="wb-margin-start-xs">
                  <Button
                    variant="primary"
                    size="small"
                    className="wb-margin-start-xs"
                    disabled={checkApplyButtonDisabled()}
                    onClick={bulkApplyAction}
                  >
                    Apply
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="tenant-members__row wb-margin-bottom-xs">
        <div className="wb-margin-end-xxs">
          <SearchInput
            label="Search Members"
            placeholder="Please enter search word"
            value={globalFilter ?? ''}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
        <div className="wb-margin-end-xxs">
          <ReactSelect
            options={roles}
            value={selectedRoles}
            onChange={(newRoles) => setSelectedRoles(newRoles)}
            isMulti={true}
            placeholder="Filter by Roles"
          />
        </div>
        <div className="wb-margin-end-xxs">
          <ReactSelect
            options={labels}
            value={selectedLabels}
            onChange={(newLabels) => setSelectedLabels(newLabels)}
            isMulti={true}
            placeholder="Filter by Labels"
          />
        </div>
        <div className="tenant-members__expiration-filter">
          <Select
            label="View membership"
            value={expirationFilter}
            onChange={(e) => setExpirationFilter(e.target.value)}
            options={[
              { id: 'all', name: 'All' },
              { id: 'active', name: 'Active' },
              { id: 'expiring', name: 'Expiring' },
              { id: 'expired', name: 'Expired' },
            ]}
            placeHolder={''}
          />
        </div>
      </div>
    </div>
  )
}

TableHeader.propTypes = {
  rowSelection: PropTypes.object,
  data: PropTypes.array,
  selectedAction: PropTypes.string,
  extendFormData: PropTypes.shape({
    tenantUserRoleExpiryDate: PropTypes.string,
  }),
  globalFilter: PropTypes.string,
  tenantRoles: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, description: PropTypes.string }),
  ),
  selectedRoles: PropTypes.array,
  selectedLabels: PropTypes.array,
  expirationFilter: PropTypes.string,
  addLabels: PropTypes.array,
  currentTenantMembers: PropTypes.array,
  actions: PropTypes.shape({
    deselectAllUsers: PropTypes.func,
    setSelectedAction: PropTypes.func,
    updateInputState: PropTypes.func,
    bulkApplyAction: PropTypes.func,
    setSelectedRoles: PropTypes.func,
    setSelectedLabels: PropTypes.func,
    setGlobalFilter: PropTypes.func,
    setExpirationFilter: PropTypes.func,
    setAddLabels: PropTypes.func,
  }),
}
